import React, { useState } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPercent,
  faChevronDown,
  faChevronRight,
  faList
} from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import { useWebSettings } from '../../../context/WebSettingsContext';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';

// Define the props interface for the Categories component
interface CategoryProps {
  selectedCategory: string;
  setSelectedCategory: React.Dispatch<React.SetStateAction<string>>;
  categories: CategoryData[];
}

// Define the CategoryData interface
interface CategoryData {
  id: number;
  category_name: string;
  category_icon: string;
  discount_percent: string;
  description: string;
}

const Categories: React.FC<CategoryProps> = ({
  selectedCategory,
  setSelectedCategory,
  categories
}) => {
  const { webData } = useWebSettings();
  const isImage = webData?.include_image === 1;
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showAllModal, setShowAllModal] = useState(false);
  const { t } = useTranslation();

  // Show only first 3 categories instead of 4
  const visibleCategories = categories.slice(0, 3);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: 0.3 }}
      className="mt-12 pb-6 border-b border-b-gray-300 dark:border-b-stone-700 relative z-40"
    >
      {/* Dropdown for screens under md */}
      <div className="relative w-full px-4 py-2 md:hidden">
        <button
          onClick={toggleDropdown}
          className="flex items-center justify-between w-full px-4 py-2 bg-white dark:bg-stone-700 rounded-md shadow-lg cursor-pointer transition-colors duration-300 ease-in-out"
        >
          <span className="text-black dark:text-zinc-100 font-bold text-lg">
            {selectedCategory === 'all'
              ? 'All Categories'
              : categories.find(cat => cat.id.toString() === selectedCategory)?.category_name}
          </span>
          <FontAwesomeIcon icon={faChevronDown} className="text-black dark:text-zinc-100" />
        </button>
        {dropdownOpen && (
          <div className="absolute left-0 right-0 mt-2 bg-white dark:bg-stone-700 rounded-md shadow-lg z-50">
            <div className="flex flex-col gap-1.5 md:gap-3.5 w-full px-4 py-2">
              {/* All categories button */}
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className={clsx(
                  'flex items-center gap-2 md:gap-3 px-4 py-2 rounded-[1rem] shadow-lg cursor-pointer flex-shrink-0 transition-colors duration-300 ease-in-out',
                  selectedCategory === 'all'
                    ? 'bg-orange-400 shadow-gray-500/40'
                    : 'bg-white dark:bg-stone-700 dark:shadow-gray-500/20 hover:bg-gray-100 hover:dark:bg-stone-600'
                )}
                onClick={() => {
                  setSelectedCategory('all');
                  setDropdownOpen(false);
                }}
              >
                {isImage && (
                  <img
                    src="https://static.vecteezy.com/system/resources/previews/019/907/094/original/fire-graphic-clipart-design-free-png.png"
                    alt="all category icon"
                    className="w-7 h-7 md:w-11 md:h-11 bg-stone-300 dark:bg-stone-400 p-0.5 md:p-1 rounded-full"
                  />
                )}
                <h3 className="text-black dark:text-zinc-100 font-bold text-lg uppercase">ALL</h3>
              </motion.div>
              {/* Individual category buttons */}
              {categories.map((category, index) => (
                <motion.div
                  key={category.id}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                  className={clsx(
                    'flex items-center gap-2 md:gap-3 px-4 py-2 rounded-[1rem] shadow-lg cursor-pointer flex-shrink-0 transition-colors duration-300 ease-in-out relative',
                    selectedCategory === category.id.toString()
                      ? 'bg-orange-400 shadow-gray-500/40'
                      : 'bg-white dark:bg-stone-700 dark:shadow-gray-500/20 hover:bg-gray-100 hover:dark:bg-stone-600'
                  )}
                  onClick={() => {
                    setSelectedCategory(category.id.toString());
                    setDropdownOpen(false);
                  }}
                >
                  {isImage && (
                    <img
                      src={category.category_icon || 'defaultIconUrl.png'}
                      alt={`${category.category_name} category icon`}
                      className="w-7 h-7 md:w-11 md:h-11 bg-stone-300 dark:bg-stone-500 p-0.5 md:p-1 rounded-full"
                    />
                  )}
                  <h3 className="text-black dark:text-zinc-100 font-bold text-lg uppercase">
                    {category.category_name}
                  </h3>
                  {/* Display discount badge if applicable */}
                  {parseFloat(category.discount_percent) > 0 && (
                    <div className="absolute -top-2 -right-2 bg-green-500 text-white text-xs font-bold px-2 py-1 rounded-full z-10">
                      <FontAwesomeIcon icon={faPercent} className="mr-1" />
                      {category.discount_percent}%
                    </div>
                  )}
                </motion.div>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Modified desktop view */}
      <div className="hidden md:block w-full px-4 py-2">
        <div className="flex items-center justify-between gap-3.5">
          {/* All categories button */}
          <motion.div
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className={clsx(
              'flex items-center gap-2 px-4 py-2 rounded-[1rem] shadow-lg cursor-pointer transition-colors duration-300 ease-in-out w-[200px] h-[60px]',
              selectedCategory === 'all'
                ? 'bg-orange-400 shadow-gray-500/40'
                : 'bg-white dark:bg-stone-700 dark:shadow-gray-500/20 hover:bg-gray-100 hover:dark:bg-stone-600'
            )}
            onClick={() => setSelectedCategory('all')}
          >
            {isImage && (
              <img
                src="https://static.vecteezy.com/system/resources/previews/019/907/094/original/fire-graphic-clipart-design-free-png.png"
                alt="all category icon"
                className="w-7 h-7 md:w-11 md:h-11 bg-stone-300 dark:bg-stone-400 p-0.5 md:p-1 rounded-full flex-shrink-0"
              />
            )}
            <h3 className="text-black dark:text-zinc-100 font-bold text-lg uppercase truncate">
              ALL
            </h3>
          </motion.div>

          {/* Visible categories */}
          {visibleCategories.map((category, index) => (
            <motion.div
              key={category.id}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.3, delay: index * 0.1 }}
              className={clsx(
                'flex items-center gap-2 px-4 py-2 rounded-[1rem] shadow-lg cursor-pointer transition-colors duration-300 ease-in-out relative w-[200px] h-[60px]',
                selectedCategory === category.id.toString()
                  ? 'bg-orange-400 shadow-gray-500/40'
                  : 'bg-white dark:bg-stone-700 dark:shadow-gray-500/20 hover:bg-gray-100 hover:dark:bg-stone-600'
              )}
              onClick={() => setSelectedCategory(category.id.toString())}
            >
              {isImage && (
                <img
                  src={category.category_icon || 'defaultIconUrl.png'}
                  alt={`${category.category_name} category icon`}
                  className="w-7 h-7 md:w-11 md:h-11 bg-stone-300 dark:bg-stone-500 p-0.5 md:p-1 rounded-full flex-shrink-0"
                />
              )}
              <h3 className="text-black dark:text-zinc-100 font-bold text-lg truncate">
                {category.category_name}
              </h3>
              {parseFloat(category.discount_percent) > 0 && (
                <div className="absolute -top-2 -right-2 bg-green-500 text-white text-xs font-bold px-2 py-1 rounded-full z-10">
                  <FontAwesomeIcon icon={faPercent} className="mr-1" />
                  {category.discount_percent}%
                </div>
              )}
            </motion.div>
          ))}

          {/* See All button */}
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setShowAllModal(true)}
            className="flex items-center gap-2 px-4 py-2 rounded-[1rem] shadow-lg cursor-pointer bg-white dark:bg-stone-700 hover:bg-gray-100 hover:dark:bg-stone-600 transition-colors duration-300 ease-in-out w-[200px] h-[60px]"
          >
            <FontAwesomeIcon icon={faList} className="text-black dark:text-zinc-100 text-xl" />
            <span className="text-black dark:text-zinc-100 font-semibold">{t('See All')}</span>
          </motion.button>
        </div>

        {/* Categories Modal */}
        <AnimatePresence>
          {showAllModal && (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
              onClick={() => setShowAllModal(false)}
            >
              <motion.div
                initial={{ scale: 0.9, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                exit={{ scale: 0.9, opacity: 0 }}
                className="bg-white dark:bg-stone-800 rounded-xl p-6 m-4 max-w-4xl w-full max-h-[80vh] overflow-y-auto"
                onClick={e => e.stopPropagation()}
              >
                <div className="flex justify-between items-center mb-6">
                  <h2 className="text-2xl font-bold text-black dark:text-zinc-100">
                    {t('All Categories')}
                  </h2>
                  <button
                    onClick={() => setShowAllModal(false)}
                    className="text-black dark:text-zinc-100 text-xl hover:text-gray-600 dark:hover:text-gray-400"
                  >
                    ✕
                  </button>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
                  {categories.map((category, index) => (
                    <motion.div
                      key={category.id}
                      whileHover={{ scale: 1.02 }}
                      className={clsx(
                        'flex items-center gap-2 px-4 py-3 rounded-xl shadow-md cursor-pointer transition-colors duration-300 ease-in-out relative',
                        selectedCategory === category.id.toString()
                          ? 'bg-orange-400 shadow-gray-500/40'
                          : 'bg-white dark:bg-stone-700 hover:bg-gray-100 hover:dark:bg-stone-600'
                      )}
                      onClick={() => {
                        setSelectedCategory(category.id.toString());
                        setShowAllModal(false);
                      }}
                    >
                      {isImage && (
                        <img
                          src={category.category_icon || 'defaultIconUrl.png'}
                          alt={`${category.category_name} category icon`}
                          className="w-7 h-7 bg-stone-300 dark:bg-stone-500 p-0.5 rounded-full flex-shrink-0"
                        />
                      )}
                      <h3 className="text-black dark:text-zinc-100 font-bold truncate">
                        {category.category_name}
                      </h3>
                      {parseFloat(category.discount_percent) > 0 && (
                        <div className="absolute -top-2 -right-2 bg-green-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                          <FontAwesomeIcon icon={faPercent} className="mr-1" />
                          {category.discount_percent}%
                        </div>
                      )}
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

export default Categories;
