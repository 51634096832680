import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPenToSquare, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { AddressSelectionProps } from '../../../types/payment';
import { useAuth } from '../../../context/AuthContext';
import api from '../../../services/api';
import AddAddressModal from '../Settings/Address/AddAddressModal';
import { Address, AddressFormData } from '../../../types/address';
import { useNotification } from '../../../context/NotificationContext';
import EditAddressModal from '../Settings/Address/EditAddressModal';
import { NotificationType, Position } from '../../../types/enums/notificationEnums';
import clsx from 'clsx';

const AddressSelection: React.FC<AddressSelectionProps> = ({
  selectedAddress,
  setSelectedAddress,
  addresses,
  isEditingPersonalInfo,
  setIsEditingPersonalInfo
}) => {
  const { t } = useTranslation();
  const [addModal, setAddModal] = useState<boolean>(false);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [placeKind, setPlaceKind] = useState<string>('Home');
  const { user } = useAuth();
  const { showNotification } = useNotification();
  const [editingAddress, setEditingAddress] = useState<Address | null>(null);
  const [formData, setFormData] = useState<AddressFormData>({
    user_id: user?.id || '',
    first_name: user?.first_name || '',
    last_name: user?.last_name || '',
    phone_number: user?.phone_number || '',
    city: '',
    district: '',
    postal_code: '',
    address_line1: '',
    title: '',
    type: 'Home'
  });
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    if (addresses.length === 0) {
      showNotification(
        t('You have no addresses. Please add one.'),
        NotificationType.Info,
        Position.TopRight
      );
    }
  }, [addresses, showNotification, t]);

  useEffect(() => {
    setFormData(prevState => ({
      ...prevState,

      type: placeKind
    }));
  }, [placeKind]);

  useEffect(() => {
    if (isEditingPersonalInfo && selectedAddress) {
      const addressToEdit = addresses.find(addr => addr.id === selectedAddress);
      if (addressToEdit) {
        setFormData({
          user_id: addressToEdit.user_id,
          first_name: addressToEdit.first_name || '',
          last_name: addressToEdit.last_name || '',
          phone_number: addressToEdit.phone_number || '',
          city: addressToEdit.city || '',
          district: addressToEdit.district || '',
          postal_code: addressToEdit.postal_code || '',
          address_line1: addressToEdit.address_line1,
          title: addressToEdit.title || '',
          type: addressToEdit.type
        });
        setPlaceKind(addressToEdit.type);
      }
    }
  }, [isEditingPersonalInfo, selectedAddress, addresses]);

  const handleChange = (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = event.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handlePhoneChange = (newPhone: string) => {
    setFormData(prevState => ({
      ...prevState,
      phone_number: newPhone
    }));
  };

  const handleAddAddress = () => {
    setFormData({
      user_id: user?.id || '',
      first_name: user?.first_name || '',
      last_name: user?.last_name || '',
      phone_number: user?.phone_number || '',
      city: '',
      district: '',
      postal_code: '',
      address_line1: '',
      title: '',
      type: 'Home'
    });
    setAddModal(true);
  };

  const handleEditAddress = (addressId: number) => {
    const addressToEdit = addresses.find(addr => addr.id === addressId);
    if (addressToEdit) {
      setEditingAddress(addressToEdit);
      setFormData({
        user_id: addressToEdit.user_id,
        first_name: addressToEdit.first_name || '',
        last_name: addressToEdit.last_name || '',
        phone_number: addressToEdit.phone_number || '',
        city: addressToEdit.city || '',
        district: addressToEdit.district || '',
        postal_code: addressToEdit.postal_code || '',
        address_line1: addressToEdit.address_line1,
        title: addressToEdit.title || '',
        type: addressToEdit.type
      });
      setPlaceKind(addressToEdit.type);
      setEditModal(true);
    }
  };

  const handleRemoveAddress = async (addressId: number) => {
    try {
      await api.delete(`/addresses/remove/${addressId}`);
      showNotification(
        t('You have removed your address.'),
        NotificationType.Success,
        Position.TopRight
      );
    } catch (error) {
      showNotification(
        t('There is an error removing your address. Please try again later.'),
        NotificationType.Error,
        Position.TopRight
      );
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await api.post('/addresses/', formData);
      showNotification(
        t('You have added your address.'),
        NotificationType.Success,
        Position.TopRight
      );
      setAddModal(false);
    } catch (error) {
      showNotification(
        t('Problem has occurred adding your address.'),
        NotificationType.Error,
        Position.TopRight
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!editingAddress) return;
    setIsSubmitting(true);
    try {
      await api.post(`/addresses/edit/${editingAddress.id}`, formData);
      showNotification(
        t('Address updated successfully.'),
        NotificationType.Success,
        Position.TopRight
      );
      setEditModal(false);
      setIsEditingPersonalInfo(false);
    } catch (error) {
      showNotification(
        t('Problem occurred while updating your address.'),
        NotificationType.Error,
        Position.TopRight
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
      {addresses.map(address => (
        <div
          key={address.id}
          className={clsx(
            'border rounded-xl p-4 hover:shadow-sm transition-all duration-300 cursor-pointer',
            selectedAddress === address.id
              ? 'border-stone-400 dark:border-orange-500 bg-slate-300 dark:bg-amber-950'
              : 'border-stone-400 dark:border-stone-600 hover:border-stone-600 dark:hover:border-orange-500 bg-gradient-to-r from-slate-200 to-slate-100 dark:from-stone-800 dark:to-stone-700'
          )}
          onClick={() => setSelectedAddress(address.id)}
        >
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-lg font-semibold text-slate-700 dark:text-orange-400 uppercase">
              {t(address.title || address.type)}
            </h2>
            <div className="flex items-center">
              {selectedAddress === address.id && (
                <FontAwesomeIcon
                  icon={faCheck}
                  className="w-5 h-5 text-stone-900 dark:text-orange-500"
                />
              )}
              <button
                className="flex justify-start items-end text-stone-900 dark:text-orange-500 hover:text-orange-400 transition-colors"
                onClick={e => {
                  e.stopPropagation();
                  handleRemoveAddress(address.id);
                }}
              >
                <FontAwesomeIcon
                  icon={faTrashAlt}
                  className="text-stone-900 dark:text-orange-500 w-5 h-5 hover:text-stone-600 dark:hover:text-orange-300 ml-2"
                />
              </button>
              <button
                className="flex justify-start items-end text-stone-900 dark:text-orange-500 hover:text-orange-400 transition-colors"
                onClick={e => {
                  e.stopPropagation();
                  handleEditAddress(address.id);
                }}
              >
                <FontAwesomeIcon
                  icon={faPenToSquare}
                  className="text-stone-900 dark:text-orange-500 w-5 h-5 hover:text-stone-600 dark:hover:text-orange-300 ml-2"
                />
              </button>
            </div>
          </div>
          <p className="text-sm text-stone-600 dark:text-zinc-300">{address.address_line1}</p>
          <p className="text-sm text-stone-600 dark:text-zinc-300">
            {address.city}
            {address.district && `, ${address.district}`}
            {address.postal_code && ` ${address.postal_code}`}
          </p>
        </div>
      ))}

      <button
        onClick={handleAddAddress}
        className="bg-gradient-to-r from-slate-200 to-slate-100 dark:from-stone-800 dark:to-stone-700 border border-stone-600 dark:border-orange-500 rounded-xl p-12 flex items-center justify-center cursor-pointer hover:shadow-sm hover:shadow-stone-400 dark:hover:shadow-orange-500"
      >
        <FontAwesomeIcon
          icon={faPlus}
          className="text-stone-900 dark:text-orange-500 w-6 h-6 mr-2"
        />
        <span className="text-lg font-semibold text-slate-700 dark:text-orange-400">
          {t('Add New Address')}
        </span>
      </button>

      <AddAddressModal
        isOpen={addModal}
        onClose={() => setAddModal(false)}
        formData={formData}
        handleChange={handleChange}
        handlePhoneChange={handlePhoneChange}
        handleSubmit={handleSubmit}
        placeKind={placeKind}
        setPlaceKind={setPlaceKind}
        isSubmitting={isSubmitting}
      />
      <EditAddressModal
        isOpen={editModal || isEditingPersonalInfo}
        onClose={() => {
          setEditModal(false);
          setIsEditingPersonalInfo(false);
        }}
        formData={formData}
        handleChange={handleChange}
        handlePhoneChange={handlePhoneChange}
        handleEditSubmit={handleEditSubmit}
        placeKind={placeKind}
        setPlaceKind={setPlaceKind}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default AddressSelection;
