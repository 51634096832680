import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import Language from '../../common/Language';
import Icons from './icons';
import Theme from './Theme';
import ContactIcon from '../../common/icons/contact';

const Sidebar: React.FC = () => {
  const [shouldAnimate, setShouldAnimate] = useState(() => {
    return !sessionStorage.getItem('hasAnimated');
  });
  const navigate = useNavigate();
  const [selectedIcon, setSelectedIcon] = React.useState<string>('homeIcon');

  useEffect(() => {
    if (shouldAnimate) {
      sessionStorage.setItem('hasAnimated', 'true');
    }

    const handleBeforeUnload = () => {
      sessionStorage.removeItem('hasAnimated');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [shouldAnimate]);

  const sidebarVariants = {
    hidden: { x: -100, opacity: 0 },
    visible: {
      x: 0,
      opacity: 1,
      transition: {
        type: 'spring',
        stiffness: 100,
        damping: 15,
        when: 'beforeChildren',
        staggerChildren: 0.1
      }
    }
  };

  const childVariants = {
    hidden: shouldAnimate ? { x: -5, opacity: 0 } : { x: 0, opacity: 1 },
    visible: { x: 0, opacity: 1 }
  };

  return (
    <motion.div
      className="fixed left-0 top-0 w-24 h-screen bg-slate-200 shadow-lg shadow-gray-500/20 dark:bg-stone-800 text-white md:flex flex-col justify-start items-center py-4 select-none outline-none z-40"
      initial={shouldAnimate ? 'hidden' : 'visible'}
      animate="visible"
      variants={sidebarVariants}
    >
      <motion.div variants={childVariants}>
        <Icons
          selectedIcon={selectedIcon}
          setSelectedIcon={setSelectedIcon}
          direction="column"
          setMobileCart={() => {}}
          mobileCart={false}
          isMobile={false}
        />
      </motion.div>
      <motion.div variants={childVariants} className="mt-auto" onClick={() => navigate('/contact')}>
        <ContactIcon shouldAnimate={shouldAnimate} color="#EA7C69" />
      </motion.div>
      <motion.div variants={childVariants}>
        <Theme shouldAnimate={shouldAnimate} />
      </motion.div>
      <motion.div className="mt-4" variants={childVariants}>
        <Language direction="column" />
      </motion.div>
    </motion.div>
  );
};

export default Sidebar;