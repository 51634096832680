import React, { useState } from 'react';
import { Order, OrderItem, OrderListProps } from '../../../types/orderList';
import { ChevronDown, ChevronUp, Repeat2 } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import Modal from '../../common/Modal';
import PDFViewer from '../../common/PDFFile';
import api from '../../../services/api';
import Spinner from '../../common/Spinner';
import Button from '../../common/Button';
import clsx from 'clsx';
import { NotificationType, Position } from '../../../types/enums/notificationEnums';
import { useNotification } from '../../../context/NotificationContext';
import { useAuth } from '../../../context/AuthContext';
import { useCart } from '../../../context/CartContext';
import { useWebSettings } from '../../../context/WebSettingsContext';

const OrderList: React.FC<OrderListProps> = ({ orders, title, description, ordersLoading }) => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const [expandedOrder, setExpandedOrder] = useState<string | null>(null);
  const [expandedOrderItems, setExpandedOrderItems] = useState<OrderItem[]>([]);
  const [loadingExpandedOrder, setLoadingExpandedOrder] = useState<boolean>(false);
  const [modalState, setModalState] = useState<{
    isOpen: boolean;
    pdfUrl: string | null;
  }>({ isOpen: false, pdfUrl: null });
  const { user } = useAuth();
  const { fetchCartItems, setLoading, loading } = useCart();
  const { webData } = useWebSettings();
  const isImage = webData?.include_image === 1;

  const toggleOrderExpansion = async (orderNumber: string) => {
    if (expandedOrder === orderNumber) {
      setExpandedOrder(null);
      setExpandedOrderItems([]);
    } else {
      setExpandedOrder(orderNumber);
      setLoadingExpandedOrder(true);
      try {
        const response = await api.get(`/order/items/${orderNumber}`);
        setExpandedOrderItems(response.data.data);
      } catch (error) {
        showNotification(
          t('Error occured fetching order items. Please try again later.'),
          NotificationType.Error,
          Position.TopRight
        );
      } finally {
        setLoadingExpandedOrder(false);
      }
    }
  };

  const handleViewInvoiceClick = (url: string, e: React.MouseEvent) => {
    e.stopPropagation();
    setModalState({ isOpen: true, pdfUrl: url });
  };

  const handleRepeatOrderClick = async (order: Order, e: React.MouseEvent) => {
    e.stopPropagation();
    setLoading(true);

    const payload = {
      orderId: order.orderNumber,
      userId: user?.id
    };

    try {
      const result = await api.post('/order/repeat', payload);
      if (result.data.success) {
        await fetchCartItems();
        showNotification(
          t('Order repeated successfully. Check your cart to proceed with the order.'),
          NotificationType.Info,
          Position.TopRight
        );
      }
    } catch (error) {
      showNotification(
        t('Failed to repeat order. Please try again later.'),
        NotificationType.Error,
        Position.TopRight
      );
    } finally {
      setLoading(false);
    }
  };

  const handleBuyAgain = async (orderItemId: string) => {
    setLoading(true);
    try {
      const response = await api.post('/order/buy-again', { orderItemId, userId: user?.id });
      if (response.data.success) {
        showNotification(
          t('Item added to cart successfully.'),
          NotificationType.Success,
          Position.TopRight
        );
        await fetchCartItems();
      }
    } catch (error) {
      showNotification(
        t('Failed to add item to cart. Please try again later.'),
        NotificationType.Error,
        Position.TopRight
      );
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setModalState({ isOpen: false, pdfUrl: null });
  };

  return (
    <div className="w-full">
      <h1 className="text-2xl md:text-3xl lg:text-4xl text-black dark:text-amber-50 font-bold mb-2 md:mb-4">
        {t(title)}
      </h1>
      <p className="text-sm md:text-base lg:text-lg text-gray-700 dark:text-zinc-300 mb-4 md:mb-6 lg:mb-8">
        {t(description)}
      </p>
      {ordersLoading && orders.length === 0 ? (
        <p className="text-sm md:text-base lg:text-lg text-slate-400 dark:text-stone-600 mb-4 md:mb-6 lg:mb-8">
          {t('You have no past orders.')}
        </p>
      ) : (
        orders.map(order => (
          <div
            key={order.orderNumber}
            className="mb-4 md:mb-6 lg:mb-8 border border-slate-300 dark:border-amber-600 rounded-xl overflow-hidden shadow-lg transition-all duration-300 ease-in-out hover:shadow-slate-300/10 dark:hover:shadow-amber-600/30"
          >
            <div
              className="flex flex-col md:flex-row justify-between items-start md:items-center p-4 md:p-6 bg-gradient-to-r from-slate-200 to-slate-100 dark:from-stone-800 dark:to-stone-700 cursor-pointer"
              onClick={() => toggleOrderExpansion(order.orderNumber)}
            >
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8 w-full mb-4 md:mb-0">
                <div className="flex flex-col">
                  <p className="font-semibold text-xs md:text-sm text-gray-700 dark:text-zinc-400 select-none">
                    {t('Order Number')}
                  </p>
                  <p className="text-sm md:text-sm lg:text-lg font-light text-gray-800 dark:text-amber-50">
                    {order.orderNumber}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-semibold text-xs md:text-sm text-gray-700 dark:text-zinc-400 select-none">
                    {t('Date Placed')}
                  </p>
                  <p className="text-sm md:text-base lg:text-lg font-light text-gray-800 dark:text-amber-50">
                    {t(order.datePlaced)}
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-semibold text-xs md:text-sm text-gray-700 dark:text-zinc-400 select-none">
                    {t('Total Price')}
                  </p>
                  <p className="text-sm md:text-base lg:text-lg font-semibold text-orange-500">
                    {order.totalAmount.toFixed(2)}€
                  </p>
                </div>
                <div className="flex flex-col">
                  <p className="font-semibold text-xs md:text-sm text-gray-700 dark:text-zinc-400 select-none">
                    {t('Status')}
                  </p>
                  <p
                    className={clsx('text-sm md:text-base lg:text-lg font-semibold', {
                      'text-red-400': order.status === 'Cancelled',
                      'text-amber-400': order.status === 'Pending',
                      'text-green-500 dark:text-green-400': order.status === 'Delivered'
                    })}
                  >
                    {t(order.status)}
                  </p>
                </div>
              </div>
              <div className="flex items-center space-x-6 md:space-x-2 lg:space-x-4 w-full md:w-auto justify-center md:ml-4">
                <Button
                  variant="primary"
                  className="flex items-center justify-center w-1/2 md:w-full text-xs md:text-sm py-1 md:py-2 md:ml-2"
                  onClick={e => handleRepeatOrderClick(order, e)}
                  disabled={loading}
                >
                  <Repeat2 className="w-3 h-3 md:w-4 md:h-4 mr-1 lg:mr-2" strokeWidth={2} />
                  {t('Repeat')}
                </Button>
                <Button
                  variant="secondary"
                  className="flex items-center justify-center w-1/2 md:w-full text-xs md:text-sm py-1 md:py-2 bg-slate-300 text-gray-700 dark:bg-zinc-500 dark:text-zinc-200"
                  onClick={e =>
                    handleViewInvoiceClick(
                      'https://cdn.bartin.edu.tr/oidb/8ff29659b6717f49171af5cad0419b6d/transkript-dilekce-ornegi.pdf',
                      e
                    )
                  }
                  disabled={true}
                >
                  {t('Invoice')}
                </Button>
                {expandedOrder === order.orderNumber ? (
                  <ChevronUp className="text-gray-700 dark:text-amber-50 w-5 h-5 md:w-12 md:h-12 ml-auto" />
                ) : (
                  <ChevronDown className="text-gray-700 dark:text-amber-50 w-5 h-5 md:w-12 md:h-12 ml-auto" />
                )}
              </div>
            </div>

            {expandedOrder === order.orderNumber && (
              <div className="bg-slate-100 dark:bg-stone-800 p-4 md:p-6 space-y-4 md:space-y-6">
                {loadingExpandedOrder ? (
                  <div className="flex justify-center items-end">
                    <Spinner />
                  </div>
                ) : (
                  expandedOrderItems.map((item, index) => (
                    <div
                      key={index}
                      className="flex flex-row md:flex-row space-x-4 md:space-x-6 items-stretch border-b border-slate-300 dark:border-stone-600 pb-4 md:pb-6 last:border-b-0 last:pb-0"
                    >
                      {isImage && (
                        <img
                          src={item.image_url}
                          alt={item.product_name}
                          className="w-24 h-24 md:w-32 md:h-28 object-contain rounded-lg select-none"
                        />
                      )}
                      <div className="flex-grow flex flex-col justify-between">
                        <div className="flex flex-col md:flex-row justify-between items-start mb-2">
                          <h3 className="font-bold text-base md:text-xl text-gray-700 dark:text-amber-50 mb-1 md:mb-0">
                            {item.product_name}
                          </h3>
                          <p className="font-bold text-base md:text-xl text-orange-500 mb-0 md:mb-0">
                            {item.price_at_time}€
                          </p>
                        </div>
                        <p className="hidden md:block text-xs lg:text-sm text-justify mb-4 text-gray-700 dark:text-zinc-300">
                          {item.description}
                        </p>
                        <div className="flex items-end md:items-center justify-between">
                          <div className="flex flex-col md:flex-row md:space-x-4">
                            <div className="flex items-center">
                              <span className="text-xs md:text-base font-semibold text-slate-700 dark:text-zinc-400 mr-2 select-none">
                                {t('Quantity')}:
                              </span>
                              <span className="text-xs md:text-base text-slate-700 dark:text-amber-50">
                                {item.quantity}
                              </span>
                            </div>
                            <div className="flex items-center">
                              <span className="text-xs md:text-base font-semibold text-slate-700 dark:text-zinc-400 mr-2 select-none">
                                {t('Size')}:
                              </span>
                              <span className="text-xs md:text-base text-slate-700 dark:text-amber-50">
                                {t(item.size_name)}
                              </span>
                            </div>
                          </div>
                          <button
                            onClick={() => handleBuyAgain(item.id)}
                            className="text-xs md:text-base font-semibold text-amber-500 hover:text-amber-400 dark:text-amber-400 dark:hover:text-amber-300 hover:underline transition-colors duration-300 select-none"
                          >
                            {t('Buy again')}
                          </button>
                        </div>
                      </div>
                    </div>
                  ))
                )}
              </div>
            )}
          </div>
        ))
      )}
      <Modal
        isOpen={modalState.isOpen}
        onClose={closeModal}
        title={t('Invoice PDF')}
        className="w-full h-auto md:max-w-5xl md:h-full"
      >
        {modalState.pdfUrl && <PDFViewer url={modalState.pdfUrl} />}
      </Modal>
    </div>
  );
};

export default OrderList;
