import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../common/Modal';
import LabeledInput from '../../../common/LabeledInput';
import Button from '../../../common/Button';
import Phone from '../Phone';
import { AddressFormData } from '../../../../types/address';
import AddressTypeSelector from './AddressTypeSelector';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Spinner from '../../../common/Spinner';

interface AddAddressModalProps {
  isOpen: boolean;
  onClose: () => void;
  formData: AddressFormData;
  handleChange: (
    event: React.ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  handlePhoneChange: (newPhone: string) => void;
  handleSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
  placeKind: string;
  setPlaceKind: (kind: string) => void;
  isSubmitting: boolean;
}

const AddAddressModal: React.FC<AddAddressModalProps> = ({
  isOpen,
  onClose,
  formData,
  handleChange,
  handlePhoneChange,
  handleSubmit,
  placeKind,
  setPlaceKind,
  isSubmitting
}) => {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<{ [key: string]: string }>({});

  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};
    if (!formData.first_name?.trim()) newErrors.first_name = t('validation.required.firstName');
    if (!formData.last_name?.trim()) newErrors.last_name = t('validation.required.lastName');
    if (!formData.phone_number?.trim())
      newErrors.phone_number = t('validation.required.phoneNumber');
    if (!formData.address_line1?.trim()) newErrors.address_line1 = t('validation.required.address');
    if (!formData.district?.trim()) newErrors.district = t('validation.required.district');
    if (!formData.city?.trim()) newErrors.city = t('validation.required.city');
    if (placeKind === 'Other' && !formData.title?.trim())
      newErrors.title = t('validation.required.title');
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleAddSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (validateForm()) {
      handleSubmit(e);
    }
  };

  const handleClose = () => {
    setErrors({});
    onClose();
  };

  useEffect(() => {
    validateForm();
  }, [formData]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={t('Add New Address')}
      className="w-[500px] text-[28px]"
    >
      <div>
        <form onSubmit={handleAddSubmit} className="px-2 md:px-4 flex flex-col gap-4">
          <div>
            <h2 className="text-stone-900 dark:text-orange-500 font-semibold mb-4">
              {t('Your Personal Information')}
            </h2>
            <p className="text-[18px] mb-2 text-stone-600 dark:text-zinc-300">
              {t('Recipient Information')}
            </p>
            <div className="flex flex-row gap-2">
              <LabeledInput
                name="first_name"
                placeholder={t('Name')}
                id="first_name"
                width="calc(60% - 0.5rem)"
                value={formData.first_name}
                onChange={handleChange}
                error={errors.first_name}
              />
              <LabeledInput
                name="last_name"
                placeholder={t('Surname')}
                id="last_name"
                width="calc(60% - 0.5rem)"
                value={formData.last_name}
                onChange={handleChange}
                error={errors.last_name}
              />
            </div>
            <div className="mt-2">
              <Phone setPhone={handlePhoneChange} phone_number={formData.phone_number} />
              {errors.phone_number && (
                <div className="flex items-center mt-1 text-red-500 text-xs">
                  <FontAwesomeIcon icon={faCircleExclamation} className="h-4 w-4 mr-1" />
                  <p>{errors.phone_number}</p>
                </div>
              )}
            </div>
          </div>
          <div>
            <h2 className="text-stone-900 dark:text-orange-500 font-semibold mb-4">
              {t('Your Address Information')}
            </h2>
            <p className="text-[18px] mb-2 text-stone-600 dark:text-zinc-300">
              {t('Delivery location address information')}
            </p>
            <AddressTypeSelector placeKind={placeKind} setPlaceKind={setPlaceKind} />
            <div>
              <div className="flex flex-col gap-2">
                <div className="flex flex-row gap-2">
                  <LabeledInput
                    name="city"
                    placeholder={t('City')}
                    id="city"
                    value={formData.city}
                    onChange={handleChange}
                    error={errors.city}
                  />
                  <LabeledInput
                    name="district"
                    placeholder={t('District')}
                    id="district"
                    value={formData.district}
                    onChange={handleChange}
                    error={errors.district}
                  />
                </div>
                <LabeledInput
                  name="postal_code"
                  placeholder={t('Postal Code')}
                  id="postal_code"
                  value={formData.postal_code}
                  onChange={handleChange}
                />
                <textarea
                  name="address_line1"
                  id="address_line1"
                  value={formData.address_line1}
                  className="bg-slate-300 dark:bg-stone-700 text-stone-600 dark:text-zinc-300 rounded-md text-[16px] min-h-[120px] resize-none py-4 px-6 placeholder:text-stone-800 dark:placeholder:text-zinc-300"
                  onChange={handleChange}
                  placeholder={t('Type your address here...')}
                ></textarea>
                {errors.address_line1 && (
                  <div className="flex items-center mb-1 text-red-500 text-xs">
                    <FontAwesomeIcon icon={faCircleExclamation} className="h-4 w-4 mr-1" />
                    <p>{errors.address_line1}</p>
                  </div>
                )}
                <p className="text-stone-600 dark:text-zinc-300 text-[16px]">
                  {t('Check And Edit Address')}
                </p>
              </div>
            </div>
            {placeKind === 'Other' && (
              <div className="mt-8">
                <LabeledInput
                  name="title"
                  placeholder={t('Name your address (neighbor, hotel, etc.)')}
                  id="title"
                  value={formData.title}
                  onChange={handleChange}
                />
              </div>
            )}
          </div>
          <div className="flex flex-row gap-2 justify-end items-center w-full h-14 ">
            <Button
              variant="cancel"
              className="w-auto md:w-32 h-auto text-[16px] "
              onClick={onClose}
            >
              {t('Cancel')}
            </Button>
            <Button
              variant="tertiary"
              className="w-auto md:w-48 h-auto text-[16px]"
              type="submit"
              disabled={
                !formData.first_name?.trim() ||
                !formData.last_name?.trim() ||
                !formData.phone_number?.trim() ||
                !formData.address_line1?.trim() ||
                !formData.district?.trim() ||
                !formData.city?.trim() ||
                isSubmitting
              }
            >
              {isSubmitting ? <Spinner size="sm" className="mx-auto" /> : t('Save Changes')}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default AddAddressModal;
