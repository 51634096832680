import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from './Sidebar/Sidebar';
import SidebarMobile from './Sidebar/SidebarMobile';
import Sidecart from './Sidecart';
import clsx from 'clsx';
import CookieConsentBanner from '../common/CookieConsentBanner';

interface LayoutProps {
  isAdmin?: boolean;
}

const PublicLayout: React.FC<LayoutProps> = ({ isAdmin }) => {
  const [mobileCart, setMobileCart] = React.useState<boolean>(false);

  return (
    <div className="flex flex-col min-h-screen overflow-x-hidden bg-zinc-50 dark:bg-stone-900">
      <div className="flex flex-1">
        <div className="hidden md:block w-24 flex-shrink-0">
          <Sidebar />
        </div>
        <main className="flex-grow overflow-y-auto">
          <div
            className={clsx(
              isAdmin ? 'max-w-[1600px]' : 'xl:max-w-7xl lg:max-w-5xl',
              'mx-auto mb-12 py-6 px-2 md:py-8 md:px-6'
            )}
          >
            <Outlet />
          </div>
        </main>
        <CookieConsentBanner />
        {!isAdmin && (
          <div className="w-0 md:w-24 xl:w-[25rem] flex-shrink-0">
            <Sidecart mobileCart={mobileCart} setMobileCart={setMobileCart} />
          </div>
        )}
      </div>
      <SidebarMobile
        setMobileCart={setMobileCart}
        mobileCart={mobileCart}
      />
    </div>
  );
};

export default PublicLayout;
