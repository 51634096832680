import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faMinus, faPlus, faPercent } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import Button from '../../common/Button';
import { ProductData, ProductModalProps, Size, Ingredient } from '../../../types/homepage';
import { useTranslation } from 'react-i18next';
import api from '../../../services/api';
import Spinner from '../../common/Spinner';
import { useAuth } from '../../../context/AuthContext';
import { useNotification } from '../../../context/NotificationContext';
import { NotificationType, Position } from '../../../types/enums/notificationEnums';
import { Link } from 'react-router-dom';
import { useCart } from '../../../context/CartContext';
import { motion, AnimatePresence } from 'framer-motion';

const ProductModal: React.FC<ProductModalProps> = ({
  modalOpen,
  setModalOpen,
  productID,
  categories
}) => {
  const { t } = useTranslation();
  const [size, setSize] = useState<Size | null>(null);
  const modalRef = useRef<HTMLDivElement>(null);
  const [productData, setProductData] = useState<ProductData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [quantity, setQuantity] = useState<number>(1);
  const [selectedIngredients, setSelectedIngredients] = useState<number[]>([]);
  const { user, isAuthenticated } = useAuth();
  const userID = user?.id;
  const { addToCart } = useCart();
  const { showNotification } = useNotification();

  // Fetch product data when modal opens
  useEffect(() => {
    const fetchProductById = async () => {
      if (!modalOpen || !productID) return;

      setIsLoading(true);
      setError(null);
      try {
        const response = await api.get(`/products/get-product/${productID}`);
        if (response.data.data) {
          const product = response.data.data as ProductData;
          setProductData(product);
          if (product.sizes && product.sizes.length > 0) {
            setSize(product.sizes[0]);
          }
          if (product.ingredients && product.ingredients.length > 0) {
            setSelectedIngredients(product.ingredients.map((ing: Ingredient) => ing.ingredient_id));
          }
        } else {
          setError('Product not found');
        }
      } catch (err) {
        setError('Failed to load product data');
      } finally {
        setIsLoading(false);
      }
    };

    fetchProductById();
  }, [productID, modalOpen]);

  // Close modal when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        setModalOpen(false);
      }
    };

    if (modalOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [modalOpen, setModalOpen]);

  const handleQuantityChange = (increment: number) => {
    setQuantity(prevQuantity => Math.max(1, prevQuantity + increment));
  };

  const toggleIngredient = (ingredientId: number) => {
    setSelectedIngredients(prev =>
      prev.includes(ingredientId) ? prev.filter(id => id !== ingredientId) : [...prev, ingredientId]
    );
  };

  const getEffectiveDiscount = (product: ProductData) => {
    if (!product || !categories) {
      return 0;
    }

    const productDiscount = product.discount_percent ? parseFloat(product.discount_percent) : 0;

    const matchingCategory = categories.find(c => c.id === product.category_id);
    const categoryDiscount =
      matchingCategory && matchingCategory.discount_percent
        ? parseFloat(matchingCategory.discount_percent)
        : 0;

    const effectiveDiscount = Math.max(productDiscount, categoryDiscount);

    return effectiveDiscount;
  };

  const calculatePrice = () => {
    if (!productData) return { basePrice: '0.00', discountedPrice: null };
    const basePrice =
      size && size.size_price
        ? parseFloat(size.size_price.toString())
        : parseFloat(productData.base_price);

    const effectiveDiscount = getEffectiveDiscount(productData);
    const discountedPrice = basePrice * (1 - effectiveDiscount / 100);

    return {
      basePrice: (basePrice * quantity).toFixed(2),
      discountedPrice: effectiveDiscount > 0 ? (discountedPrice * quantity).toFixed(2) : null
    };
  };

  if (!modalOpen) return null;

  const handleAddToCart = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!productData || !size) {
      showNotification(
        'Unable to add product to cart. Please try again.',
        NotificationType.Error,
        Position.TopRight
      );
      return;
    }

    // Preparing the data to send to the API
    const cartItemData = {
      size_id: size ? size.size_id : null, // Make sure this matches your size object structure
      quantity: quantity,
      item_note: (event.currentTarget.productNote as HTMLTextAreaElement).value.trim()
    };

    const cartItemIngredientsData = {
      ingredients: selectedIngredients.map(id => ({
        ingredient_id: id,
        is_included: true
      }))
    };

    const requestData = {
      userId: userID,
      cartItemData,
      cartItemIngredientsData
    };

    try {
      setIsAdding(true);
      await addToCart(requestData);
      setModalOpen(false);
    } finally {
      setIsAdding(false);
    }
  };

  return (
    <AnimatePresence>
      {modalOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50"
        >
          <motion.div
            ref={modalRef}
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="bg-white dark:bg-stone-800 w-[350px] md:w-[450px] max-h-[90vh] rounded-3xl shadow-xl"
          >
            {isLoading ? (
              <div className="flex justify-center items-center h-64">
                <Spinner />
              </div>
            ) : error ? (
              <div className="flex justify-center items-center h-64">
                <p className="text-red-500">{error}</p>
              </div>
            ) : productData ? (
              <form onSubmit={handleAddToCart}>
                <div className="bg-orange-400 max-w-[450px] h-auto flex justify-center rounded-3xl p-2  relative shadow-lg shadow-gray-500/20 dark:shadow-orange-900 dark:shadow-md">
                  <button
                    className="absolute top-4 right-4 cursor-pointer p-1 rounded-full flex justify-center items-center"
                    onClick={() => setModalOpen(false)}
                  >
                    <FontAwesomeIcon icon={faXmark} className="text-white text-2xl" />
                  </button>
                  <img
                    src={productData.image_url}
                    alt={productData.product_name}
                    className="h-32 md:h-40 lg:h-48  object-cover"
                  />
                </div>
                <div className="h-80 overflow-y-auto">
                  <div className="px-6 py-2 mt-4 flex flex-col gap-6">
                    <div>
                      <h3 className="text-2xl md:text-3xl font-semibold text-black dark:text-white break-words">
                        {productData.product_name}
                      </h3>
                      <p className="text-md md:text-lg text-stone-400 dark:text-stone-300 mt-2">
                        {productData.description}
                      </p>
                    </div>
                    {productData.sizes && productData.sizes.length > 0 && (
                      <div className="flex gap-4 items-center flex-wrap">
                        <p className="text-lg md:text-xl font-medium text-stone-400 dark:text-stone-300">
                          {t('Size')}:
                        </p>
                        {productData.sizes.map(sizeOption => (
                          <button
                            key={sizeOption.size_name}
                            className={clsx(
                              'text-xs md:text-md px-4 py-2 rounded-3xl',
                              size?.size_name === sizeOption.size_name
                                ? 'bg-orange-500 text-white font-medium'
                                : 'bg-zinc-200 dark:bg-zinc-100'
                            )}
                            onClick={() => setSize(sizeOption)}
                            type="button"
                          >
                            {sizeOption.size_name}
                          </button>
                        ))}
                      </div>
                    )}
                    {productData.ingredients && productData.ingredients.length > 0 && (
                      <div>
                        <p className="text-lg md:text-xl font-medium text-black dark:text-white mb-2">
                          Ingredients
                        </p>
                        <div className="flex gap-4 flex-wrap">
                          {productData.ingredients.map(ingredient => (
                            <button
                              key={ingredient.ingredient_id}
                              className={clsx(
                                'text-xs md:text-md px-4 py-2 rounded-3xl',
                                selectedIngredients.includes(ingredient.ingredient_id)
                                  ? 'bg-orange-500 text-white'
                                  : 'bg-zinc-200 dark:bg-zinc-100'
                              )}
                              onClick={() => toggleIngredient(ingredient.ingredient_id)}
                              type="button"
                            >
                              {ingredient.ingredient_name}
                            </button>
                          ))}
                        </div>
                      </div>
                    )}
                    <div className="flex flex-col gap-3">
                      <label
                        htmlFor="productNote"
                        className="text-lg md:text-xl font-medium text-black dark:text-white"
                      >
                        {t('Product Note')}:
                      </label>
                      <textarea
                        id="productNote"
                        name="productNote"
                        placeholder={t('You can put your notes for the product here.')}
                        className="px-4 py-2 rounded-xl border border-stone-200 h-24 resize-none dark:bg-stone-700 dark:border-stone-700 dark:placeholder:text-stone-300 placeholder:text-sm md:placeholder:text-md"
                      />
                    </div>
                  </div>
                </div>
                <div className="rounded-3xl bg-slate-100 dark:bg-stone-700 py-1.5 px-6 md:px-6 md:py-3 shadow-lg shadow-gray-500/10">
                  <div className="flex flex-row justify-between items-center">
                    <div className="flex items-center">
                      {calculatePrice().discountedPrice ? (
                        <>
                          <p className="text-gray-500 dark:text-gray-400 line-through text-lg mr-2">
                            {calculatePrice().basePrice}€
                          </p>
                          <p className="text-black text-2xl font-medium dark:text-white mr-2">
                            {calculatePrice().discountedPrice}€
                          </p>
                        </>
                      ) : (
                        <p className="text-black text-2xl font-medium dark:text-white mr-2">
                          {calculatePrice().basePrice}€
                        </p>
                      )}
                      {getEffectiveDiscount(productData) > 0 && (
                        <div className="bg-green-500 text-white text-xs font-bold px-2 py-1 rounded-full">
                          <FontAwesomeIcon icon={faPercent} className="mr-1" />
                          {getEffectiveDiscount(productData)}%
                        </div>
                      )}
                    </div>
                    <div className="bg-stone-900 px-4 py-2 rounded-[3rem] flex gap-3 items-center">
                      <button
                        type="button"
                        className="bg-zinc-600 dark:bg-slate-200 rounded-[5rem] px-1.5 py-0.5"
                        onClick={() => handleQuantityChange(-1)}
                      >
                        <span className="text-white dark:text-black">
                          <FontAwesomeIcon icon={faMinus} />
                        </span>
                      </button>
                      <p className="text-white font-semibold">{quantity}</p>
                      <button
                        type="button"
                        className="bg-zinc-600 dark:bg-slate-200 rounded-[5rem] px-1.5 py-0.5"
                        onClick={() => handleQuantityChange(1)}
                      >
                        <span className="text-white dark:text-black">
                          <FontAwesomeIcon icon={faPlus} />
                        </span>
                      </button>
                    </div>
                  </div>
                  {isAuthenticated ? (
                    <Button
                      variant="tertiary"
                      className="w-full mt-4 h-12 mb-3"
                      type="submit"
                      disabled={isAdding}
                    >
                      <span className="text-lg font-medium">
                        {isAdding ? t('Adding to cart...') : t('Add to Cart')}
                      </span>
                    </Button>
                  ) : (
                    <Link to="/login">
                      <Button variant="tertiary" className="w-full mt-4 h-12 mb-3" type="submit">
                        <span className="text-lg font-medium">{t('Login to order')}</span>
                      </Button>
                    </Link>
                  )}
                </div>
              </form>
            ) : null}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ProductModal;
