import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEye, faSearch } from '@fortawesome/free-solid-svg-icons';
import { AdminOrder } from '../../../../../types/orderList';
import api from '../../../../../services/api';
import { useNotification } from '../../../../../context/NotificationContext';
import { NotificationType, Position } from '../../../../../types/enums/notificationEnums';
import Spinner from '../../../../common/Spinner';
import StatusDropdown from './StatusDropdown';
import Button from '../../../../common/Button';
import Modal from '../../../../common/Modal'; // Modal bileşenini import ettik
import { Address } from '../../../../../types/address';
import DeleteWarning from '../../common/DeleteWarning';
import debounce from 'lodash/debounce';

const Orders: React.FC = () => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState<AdminOrder[]>([]);
  const [displayedOrders, setDisplayedOrders] = useState<AdminOrder[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { showNotification } = useNotification();
  const [selectedOrder, setSelectedOrder] = useState<AdminOrder | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [address, setAddress] = useState<Address | null>(null);
  const [warningModal, setWarningModal] = useState<boolean>(false);
  const [warningDescription, setWarningDescription] = useState<string>('');
  const [onConfirmAction, setOnConfirmAction] = useState<() => void>(() => {});
  const [searchTerm, setSearchTerm] = useState<string>('');

  useEffect(() => {
    fetchOrders();
  }, []);

  useEffect(() => {
    const filteredOrders = orders.filter(
      order =>
        String(order.orderNumber).includes(searchTerm) ||
        order.paymentMethod.toLowerCase().includes(searchTerm.toLowerCase()) ||
        order.status.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setDisplayedOrders(filteredOrders);
  }, [orders, searchTerm]);

  const fetchOrders = async () => {
    setLoading(true);
    try {
      const response = await api.get('/admin/orders');
      const fetchedOrders = response.data.data;

      const transformedOrders: AdminOrder[] = fetchedOrders.map((order: any) => ({
        orderNumber: order.id,
        datePlaced: new Date(order.order_date).toLocaleDateString(),
        totalAmount: parseFloat(order.total_price).toFixed(2),
        status: order.status.charAt(0).toUpperCase() + order.status.slice(1),
        paymentMethod: order.payment_method,
        addressId: order.address_id,
        generalNote: order.general_note
      }));

      setOrders(transformedOrders);
    } catch (error) {
      showNotification(
        t('Error occurred fetching orders. Please try again later.'),
        NotificationType.Error,
        Position.TopRight
      );
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteOrder = async (orderNumber: string) => {
    try {
      const response = await api.delete(`/admin/delete-order/${orderNumber}`);
      if (response.data.success) {
        setOrders(orders.filter(order => order.orderNumber !== orderNumber));
        showNotification(
          t('Order deleted successfully.'),
          NotificationType.Success,
          Position.TopRight
        );
      }
    } catch (error) {
      showNotification(
        t('Error deleting order. Please try again.'),
        NotificationType.Error,
        Position.TopRight
      );
    }
  };

  const handleStatusChange = async (orderNumber: string, newStatus: string) => {
    try {
      const response = await api.put(`/admin/update-order/${orderNumber}`, {
        orderData: { status: newStatus }
      });
      if (response.data.success) {
        setOrders(
          orders.map(order =>
            order.orderNumber === orderNumber ? { ...order, status: newStatus } : order
          )
        );
        showNotification(
          t('Order status updated successfully.'),
          NotificationType.Success,
          Position.TopRight
        );
      }
    } catch (error) {
      showNotification(
        t('Error updating order status. Please try again.'),
        NotificationType.Error,
        Position.TopRight
      );
    }
  };

  const handleStatusChangeWithWarning = (orderNumber: string, newStatus: string) => {
    setWarningDescription(t('Are you sure you want to change the status of this order?'));
    setOnConfirmAction(() => () => handleStatusChange(orderNumber, newStatus));
    setWarningModal(true);
  };

  const handleDeleteOrderWithWarning = (orderNumber: string) => {
    setWarningDescription(t('Are you sure you want to delete this order?'));
    setOnConfirmAction(() => () => handleDeleteOrder(orderNumber));
    setWarningModal(true);
  };

  const openModal = async (order: AdminOrder) => {
    try {
      const response = await api.get(`/addresses/${order.addressId}`);
      const address = response.data.data;
      setAddress(address);
      setSelectedOrder(order);
      setIsModalOpen(true);
    } catch (error) {
      showNotification(
        t('Error occurred fetching address. Please try again later.'),
        NotificationType.Error,
        Position.TopRight
      );
    }
  };

  const closeModal = () => {
    setSelectedOrder(null);
    setIsModalOpen(false);
  };

  const debouncedSearch = useCallback(
    debounce((term: string) => {
      setSearchTerm(term);
    }, 300),
    []
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(e.target.value);
  };

  return (
    <div className="w-full text-stone-900 dark:text-zinc-300">
      <h1 className="flex justify-start items-center pt-4 font-bold text-[40px] text-stone-900 dark:text-orange-600">
        {t('Orders')}
      </h1>
      <p className="text-stone-900 dark:text-zinc-200">{t('Manage orders of your customers.')}</p>

      <div className="mt-8 flex justify-between items-center">
        <div className="relative">
          <input
            type="text"
            placeholder={t('Search orders...')}
            onChange={handleSearch}
            className="pl-10 pr-4 py-2 text-md border border-gray-400 dark:border-0 md:text-lg rounded-2xl dark:bg-stone-700 dark:text-stone-300 focus:outline-none focus:ring-2 focus:ring-stone-500"
          />
          <FontAwesomeIcon
            icon={faSearch}
            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
          />
        </div>
      </div>

      {loading ? (
        <div className="flex items-center justify-center mt-10">
          <Spinner />
        </div>
      ) : (
        <table className="w-full mt-8 border-collapse">
          <thead className="bg-slate-300 dark:bg-stone-800">
            <tr>
              <th className="px-4 py-2 text-left border border-gray-600 dark:border-gray-400">
                {t('Order Number')}
              </th>
              <th className="px-4 py-2 text-left border border-gray-600 dark:border-gray-400">
                {t('Date Placed')}
              </th>
              <th className="px-4 py-2 text-left border border-gray-600 dark:border-gray-400">
                {t('Payment')}
              </th>
              <th className="px-4 py-2 text-left border border-gray-600 dark:border-gray-400">
                {t('Total Price')}
              </th>
              <th className="px-4 py-2 text-left border border-gray-600 dark:border-gray-400">
                {t('Status')}
              </th>
              <th className="px-4 py-2 text-left border border-gray-600 dark:border-gray-400">
                {t('Actions')}
              </th>
            </tr>
          </thead>
          <tbody className="text-stone-900 dark:text-zinc-300 bg-slate-200 dark:bg-stone-700">
            {displayedOrders.map(order => (
              <tr key={order.orderNumber} className="hover:bg-slate-300 dark:hover:bg-stone-600">
                <td className="px-4 py-2 border border-gray-600 dark:border-gray-400">
                  {order.orderNumber}
                </td>
                <td className="px-4 py-2 border border-gray-600 dark:border-gray-400">
                  {order.datePlaced}
                </td>
                <td className="px-4 py-2 border border-gray-600 dark:border-gray-400">
                  {t(order.paymentMethod)}
                </td>
                <td className="px-4 py-2 border border-gray-600 dark:border-gray-400">
                  {t('dollar symbol')}
                  {order.totalAmount}
                  {t('other symbols')}
                </td>
                <td className="px-4 py-2 border border-gray-600 dark:border-gray-400">
                  <StatusDropdown
                    status={order.status}
                    onStatusChange={newStatus =>
                      handleStatusChangeWithWarning(order.orderNumber, newStatus)
                    }
                  />
                </td>
                <td className="px-4 py-2 border border-gray-600 dark:border-gray-400">
                  <div className="flex space-x-2">
                    <Button
                      variant="tertiary"
                      onClick={() => openModal(order)}
                      className="w-1/2 text-xs md:text-lg"
                      disabled={loading}
                    >
                      <FontAwesomeIcon icon={faEye} />
                    </Button>
                    <Button
                      variant="cancel"
                      onClick={() => handleDeleteOrderWithWarning(order.orderNumber)}
                      className="w-1/2 text-xs md:text-lg"
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        title={t('Order Details')}
        className="w-full h-auto md:max-w-md"
      >
        {selectedOrder && (
          <>
            <h3 className="text-slate-800 dark:text-orange-600 font-semibold text-lg mb-2">
              {t('Address')}
            </h3>
            <p className="mb-4 text-slate-800 dark:text-zinc-200">{address?.address_line1}</p>
            <p className="text-slate-800 dark:text-zinc-200">
              {address?.city}
              {address?.district ? `, ${address.district}` : ''}
            </p>
            <p className="mb-4 font-medium text-slate-800 dark:text-zinc-200">
              {address?.postal_code && t('Postal Code:')}
              <span className="font-normal">{address?.postal_code}</span>
            </p>
            <h3 className="text-slate-800 dark:text-orange-600 font-semibold mb-2 text-lg">
              {t('Note')}
            </h3>
            <p className="text-slate-800 dark:text-zinc-200">
              {selectedOrder.generalNote || t('No note provided.')}
            </p>
          </>
        )}
        <div className="flex items-end justify-end">
          <Button variant="primary" onClick={closeModal} className="mt-4 px-4">
            {t('Close')}
          </Button>
        </div>
      </Modal>
      <DeleteWarning
        modalOpen={warningModal}
        setModalOpen={setWarningModal}
        description={warningDescription}
        onConfirm={onConfirmAction}
      />
    </div>
  );
};

export default Orders;
