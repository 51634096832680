import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faCreditCard,
  faMoneyBills,
  faPenToSquare,
  faWifiStrong
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import GoBackButton from '../../common/GoBackButton';
import { useAuth } from '../../../context/AuthContext';
import { useCart } from '../../../context/CartContext';
import AddressSelection from './AddressSelection';
import OrderDetails from './OrderDetails';
import OrderSummary from './OrderSummary';
import { OrderDetails as OrderDetailsType } from '../../../types/payment';
import api from '../../../services/api';
import { useNotification } from '../../../context/NotificationContext';
import { NotificationType, Position } from '../../../types/enums/notificationEnums';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../common/Spinner';
import { Address } from '../../../types/address';
import { motion, AnimatePresence } from 'framer-motion';

const PaymentPage: React.FC = () => {
  const [paymentType, setPaymentType] = useState('cash');
  const [isPaymentMenuOpen, setIsPaymentMenuOpen] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<number | null>(null);
  const [isEditingPersonalInfo, setIsEditingPersonalInfo] = useState(false);
  const [addresses, setAddresses] = useState<Address[]>([]);
  const paymentMenuRef = useRef<HTMLDivElement>(null);
  const [isAddressesLoading, setIsAddressesLoading] = useState(true);
  const [isOrderDetailsLoading, setIsOrderDetailsLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [orderDetails, setOrderDetails] = useState<OrderDetailsType[] | null>(null);
  const [orderNote, setOrderNote] = useState('');
  const { t } = useTranslation();
  const { user, isAuthenticated } = useAuth();
  const { cartId, calculateSubTotal, resetCart } = useCart();
  const { showNotification } = useNotification();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    } 
    const fetchInitialData = async () => {
      setIsLoading(true);

      if (cartId) {
        setIsOrderDetailsLoading(true);
        try {
          const response = await api.get(`/order/details/${cartId}`);
          if (response.data.success) {
            setOrderDetails(response.data.data);
          }
        } catch (error) {
          showNotification(
            t('Failed to fetch order details. Please try again.'),
            NotificationType.Error,
            Position.TopRight
          );
        } finally {
          setIsOrderDetailsLoading(false);
        }
      }

      setIsAddressesLoading(true);
      try {
        const response = await api.get('/addresses/user/' + user?.id);
        if (response.data.success) {
          const addressList = response.data.data;
          setAddresses(addressList);
        }
      } catch (error) {
        showNotification(
          t('Failed to fetch addresses. Please try again later.'),
          NotificationType.Error,
          Position.TopRight
        );
      } finally {
        setIsAddressesLoading(false);
      }
    };

    fetchInitialData();
  }, [isAuthenticated, user?.id, cartId, showNotification, t, navigate]);

  useEffect(() => {
    setIsLoading(isAddressesLoading || isOrderDetailsLoading);
  }, [isAddressesLoading, isOrderDetailsLoading]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (paymentMenuRef.current && !paymentMenuRef.current.contains(event.target as Node)) {
        setIsPaymentMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleCompleteOrder = async () => {
    if (!selectedAddress) {
      return;
    }

    const orderData = {
      address_id: selectedAddress,
      payment_method: paymentType,
      total_price: calculateSubTotal(),
      general_note: orderNote
    };

    setIsLoading(true);

    try {
      await api.post('/order/complete', {
        userId: user?.id,
        orderData: orderData,
        cartId: cartId
      });
      resetCart();
      setTimeout(() => {
        navigate('/');
        showNotification(
          t('Order completed successfully.'),
          NotificationType.Success,
          Position.TopRight
        );
      }, 100);
    } catch (error) {
      showNotification(
        t('Failed to fetch cart items. Please try again.'),
        NotificationType.Error,
        Position.TopRight
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handlePersonalInfo = () => {
    if (!selectedAddress) {
      showNotification(
        t('Please select an address before editing personal information.'),
        NotificationType.Warning,
        Position.TopRight
      );
      return;
    }
    setIsEditingPersonalInfo(!isEditingPersonalInfo);
  };

  const paymentMethods = [
    {
      id: 'cash',
      name: t('Cash on Delivery'),
      icon: faMoneyBills
    },
    {
      id: 'contactless',
      name: t('Contactless on Delivery'),
      icon: faWifiStrong
    }
  ];

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <Spinner />
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto p-8 text-zinc-200 min-h-screen">
      <h1 className="text-2xl md:text-4xl font-bold mb-6 text-black dark:text-amber-50">
        {t('Order Review & Checkout')}
      </h1>
      <div className="flex mb-4 justify-between">
        <p className="text-base md:text-lg text-black dark:text-amber-50">
          {t('Please choose an address or add one.')}
        </p>
        <GoBackButton
          to="/"
          className="hidden md:flex text-lg select-none dark:text-stone-200 text-stone-800 hover:text-gray-500"
        >
          {t('Go back to homepage.')}
        </GoBackButton>
      </div>

      <div className="flex flex-col lg:flex-row gap-8">
        <div className="w-full lg:w-2/3">
          <AddressSelection
            selectedAddress={selectedAddress}
            setSelectedAddress={setSelectedAddress}
            addresses={addresses}
            isEditingPersonalInfo={isEditingPersonalInfo}
            setIsEditingPersonalInfo={setIsEditingPersonalInfo}
          />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
            <div className="bg-gradient-to-r bg-slate-200 dark:bg-stone-800 border border-slate-300 dark:border-stone-700 rounded-xl p-4 shadow-sm shadow-slate-300 dark:shadow-none">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg md:text-xl font-semibold text-stone-900 dark:text-orange-400 cursor-default">
                  {t('Personal Information')}
                </h2>
                <button
                  onClick={handlePersonalInfo}
                  className="text-orange-500 hover:text-orange-400 transition-colors"
                >
                  <FontAwesomeIcon
                    icon={faPenToSquare}
                    className="text-stone-900 dark:text-orange-500 w-5 h-5 hover:text-stone-600 dark:hover:text-orange-300"
                  />
                </button>
              </div>
              {selectedAddress ? (
                <div className="flex flex-col text-stone-900 dark:text-zinc-200">
                  <p className="font-semibold">
                    {addresses.find(a => a.id === selectedAddress)?.first_name}{' '}
                    <span>{addresses.find(a => a.id === selectedAddress)?.last_name}</span>
                  </p>
                  <p>{addresses.find(a => a.id === selectedAddress)?.phone_number}</p>
                  <p>{user?.email}</p>
                </div>
              ) : (
                <div className="flex flex-col text-stone-900 dark:text-zinc-200 mt-8">
                  <p className="text-slate-400 dark:text-stone-500">
                    {t('Not selected an address yet!')}
                  </p>
                </div>
              )}
            </div>

            <div className="bg-slate-200 dark:bg-stone-800 border border-slate-300 dark:border-stone-700 rounded-xl p-4 h-40 shadow-sm shadow-slate-300 dark:shadow-none">
              <h2 className="text-lg md:text-xl font-semibold mb-6 text-stone-900 dark:text-orange-400">
                {t('Payment Method')}
              </h2>
              <div className="relative" ref={paymentMenuRef}>
                <button
                  onClick={() => setIsPaymentMenuOpen(!isPaymentMenuOpen)}
                  className="w-full bg-slate-100 dark:bg-stone-700 shadow-md shadow-slate-300 dark:shadow-none text-left px-4 py-3 rounded-xl flex items-center justify-between focus:outline-none focus:ring-2 focus:ring-slate-400 dark:focus:ring-stone-600 select-none"
                >
                  <span className="flex items-center text-stone-900 dark:text-zinc-200">
                    <FontAwesomeIcon
                      icon={paymentMethods.find(m => m.id === paymentType)?.icon || faCreditCard}
                      className="h-6 w-6 mr-2 text-stone-900 dark:text-zinc-200"
                      transform={paymentType === 'contactless' ? { rotate: 90 } : undefined}
                    />
                    {paymentMethods.find(m => m.id === paymentType)?.name}
                  </span>
                  <FontAwesomeIcon
                    icon={faChevronDown}
                    className={`h-4 w-4 transition-transform text-stone-900 dark:text-zinc-200 ${
                      isPaymentMenuOpen ? 'transform rotate-180' : ''
                    }`}
                  />
                </button>
                <AnimatePresence>
                  {isPaymentMenuOpen && (
                    <motion.div
                      initial={{ opacity: 0, y: -10 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -10 }}
                      transition={{ duration: 0.2 }}
                      className="absolute mt-2 w-full bg-slate-200 dark:bg-stone-800 rounded-xl shadow-lg z-30 select-none"
                    >
                      {paymentMethods.map(method => (
                        <motion.button
                          key={method.id}
                          onClick={() => {
                            setPaymentType(method.id);
                            setIsPaymentMenuOpen(false);
                          }}
                          className="w-full text-left px-4 py-3 flex items-center text-stone-900 dark:text-zinc-200 hover:bg-slate-300 dark:hover:bg-stone-600 hover:rounded-xl focus:outline-none focus:bg-stone-600"
                        >
                          <FontAwesomeIcon
                            icon={method.icon || faCreditCard}
                            className="h-6 w-6 mr-2 text-stone-900 dark:text-zinc-200"
                            transform={method.id === 'contactless' ? { rotate: 90 } : undefined}
                          />
                          {method.name}
                        </motion.button>
                      ))}
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
            </div>
          </div>
          <OrderDetails
            orderDetails={orderDetails}
            orderNote={orderNote}
            setOrderNote={setOrderNote}
          />
        </div>

        <div className="w-full lg:w-1/3">
          <OrderSummary
            selectedAddress={selectedAddress}
            orderDetails={orderDetails}
            paymentType={paymentType}
            onCompleteOrder={handleCompleteOrder}
          />
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;
